import { Frame, addPropertyControls, ControlType } from "framer"
import { useEffect, useRef } from "react"

interface EmbedComponentProps {
    embedCode: string
    width: number
    height: number
}

export function EmbedComponent(props: EmbedComponentProps) {
    const { embedCode, width, height } = props
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === "embedCode") {
                if (containerRef.current) {
                    containerRef.current.innerHTML = event.data.code
                }
            }
        }

        window.addEventListener("message", handleMessage)
        window.parent.postMessage({ type: "getEmbedCode" }, "*")

        return () => {
            window.removeEventListener("message", handleMessage)
        }
    }, [])

    return (
        <Frame width={width} height={height}>
            <div ref={containerRef} />
        </Frame>
    )
}

EmbedComponent.defaultProps = {
    width: 400,
    height: 400,
    embedCode: "",
}

addPropertyControls(EmbedComponent, {
    embedCode: {
        title: "Embed Code",
        type: ControlType.String,
        defaultValue: "",
    },
})
